

.badge{
  cursor: pointer;
}

.pad-right{
  margin-right: 10px;
}

.purple {
  background-color: #3117b8
}

.full-size-text {
  font-size: 100%;
}

.table-100pc {
  height: 100%;
  overflow:auto;
}

.table-purple {
  background-color: #efe1ff;
}


.modal-50w {
  width: 75% !important;
  max-width: unset !important;
}

.rightalign {
  text-align:right;
}

.smallcell {
  width: 0.1%;
}

.xaxis{
  stroke: #000;
}

.white{
  fill: white
}

.pointer-cursor {cursor: pointer;}

.pointer-no {cursor: not-allowed;}

.number-text {text-align: end;}

.justify-content-md-center {
  justify-content: center!important;
}

.width80pc{
  width: 70%;
}

.modal-content {
  overflow: auto;
}

.link{
  color: #007bff;
  fill: #007bff;
}
